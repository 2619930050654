import Footer from '@/sections/footer'

import { CookieConsent } from '@/components/cookie-consent'

import '../index.css'
import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource-variable/space-grotesk'

import Redes from '@/sections/redes'

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <CookieConsent />
      <main>{children}</main>
      <Redes />
      <Footer />
    </>
  )
}
